import React from 'react';

import Layout from '../components/layout';
import Seo from "../components/seo"
import GraphicPortfolio from '../components/GraphicPortfolio';
import ComingSoon from '../components/ComingSoon';

const uxDesign = () =>  ( 
  
  <Layout>
        <Seo title="UX/UI Design" />
        <ComingSoon />
        
   
    </Layout>
    
  
)

export default uxDesign;
