import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import ProjectThumb from './ProjectThumb';

const  GraphicPortfolio = () => {

    //Import json projects
    const projects = require('../../data/projects.json')

    return (
        <section id = 'graphic-portfolio-section' style = {sectionStyle}>

    <div className = 'graphic-heading-wrapper text-center'>
        <h1 className = 'graphic-heading' style = {headingStyle}>Graphic Design Portfolio</h1>
        <p className = 'graphic-subtext' style = {subtextStyle}><span className = 'line-1'>Select a project to view</span> <span className = 'line-2'>in full screen</span></p>

        <div className = 'graphic-projects-wrapper container-fluid' style = {projectsWrapperStyle}>
            <div className = 'row'>

                {
                    projects.map(project => {
                        return (
                            <ProjectThumb project = {project} />
                        )
                    })
                }
               
            </div>
        </div>
    </div>
  </section>
    )
}
  
  


const sectionStyle = {
    paddingLeft:'100px',
    paddingRight:'100px',
    minHeight:'100vh',
    color:'#464748'
}

const headingStyle = {
    fontSize:'40px',
    marginTop:'50px',
    fontWeight:'normal',
    color:"#fd0d1b"
}

const subtextStyle = {
    fontSize:'22px'
}

const imageStyle = {
    width:'100%',
    height:'450px'
}

const projectsWrapperStyle = {
    marginTop:'50px'
}

export default GraphicPortfolio;
