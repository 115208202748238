import React, {useEffect} from 'react'
import { StaticImage } from "gatsby-plugin-image"
import AOS from 'aos'


const ComingSoon = () => { 

    return (
        <div style = {containerStyle} className = 'container-fluid text-center mx-auto'>
            <img src = '/soon-gif.gif' style = {illustrationStyle} className = 'coming-soon'></img>
        </div>
    )
}

   const containerStyle = {
       minHeight:'100vh',
       paddingTop:'50px'
   }

   const illustrationStyle = {
    height:'600px',
    width:'600px',
    objectFit:'scale-down'
   }

export default ComingSoon
